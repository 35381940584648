/* eslint-disable require-jsdoc */
class Answer {
  imageName = null;
  correctAnswer = null;
  answer = null;

  constructor(imageName, correctAnswer, answer) {
    this.imageName = imageName;
    this.correctAnswer = correctAnswer;
    this.answer = answer;
  }

  toJson() {
    return {
      answer: this.answer,
      image_name: this.imageName,
      correct_answer: this.correctAnswer,
    };
  };
};

export default Answer;
