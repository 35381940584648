import styled from 'styled-components';

const Button = styled.button`
  cursor: pointer;
  color: unset;
  outline: none;
  background-color: transparent;
  padding: 8px 16px;
  box-sizing: border-box;
  border: 2px solid ${({theme}) => theme.tertiary};
  border-radius: 24px;
  min-width: 128px;
  text-align: center;

  &:hover {
    box-shadow: 1px 1px 2px 0 ${({theme}) => theme.tertiary};
  }

  &:disabled {
    border-color: #999999;
    background-color: unset;
    color: #666666;
    box-shadow: unset;
    cursor: unset;
  }

  &:active {
    box-shadow: unset;
  }
`;

export {Button};
