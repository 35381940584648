import React from 'react';
import PropTypes from 'prop-types';
import {
  TextWrapper,
  LinkedText,
  Text,
  ButtonWrapper,
  Button,
} from '../../components';

const Welcome = ({onStart}) => (
  <React.Fragment>
    <TextWrapper margin="sm">
      <Text>Lung ADCA vs SCC challenge</Text>
    </TextWrapper>
    <TextWrapper>
      <Text size="sm">Crowdsource Morphologic Survey</Text>
    </TextWrapper>
    <ButtonWrapper>
      <Button onClick={onStart}>
        <Text>Start</Text>
      </Button>
    </ButtonWrapper>
    <TextWrapper align="left" margin="xs">
      <Text size="xs">Authors:</Text>
    </TextWrapper>
    <TextWrapper align="left" margin="xs">
      <LinkedText size="xs" target="_blank" href="https://louisville.edu/medicine/departments/pathology/residency/residents">
        Ali Ismail MBBS
      </LinkedText>
    </TextWrapper>
    <TextWrapper align="left">
      <LinkedText size="xs" target="_blank" href="https://louisville.edu/medicine/departments/pathology/faculty/dibson-dibe-gondim-m-d">
        Dibson Dibe Gondim MD
      </LinkedText>
    </TextWrapper>
    <TextWrapper>
      <Text size="xs">
        Images based upon data generated by the TCGA Research Network
      </Text>
      <br/>
      <LinkedText size="xs" target="_blank" href="https://www.cancer.gov/tcga">https://www.cancer.gov/tcga</LinkedText>
    </TextWrapper>
  </React.Fragment>
);

Welcome.propTypes = {
  onStart: PropTypes.func,
};

export default Welcome;
