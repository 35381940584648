import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  TextWrapper,
  Wrapper,
  Text,
  ButtonWrapper,
  Button,
  BorderedImage,
} from '../../../components';
import {TextPositive,TextNegative} from '../../../components/Text';
import {
  TIME_BUTTON_DOWN,
} from '../../../constants';

const Question = ({progress, imageURL, possibleAnswers, onAnswerQuestion}) => {
  const [disabled, setDisabled] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [correctShowFeedBack, setCorrectShowFeedBack] = useState(false);
  const [feedBackMensageLine1, setFeedBackMensageLine1] = useState("none");
  const [feedBackMensageLine2, setFeedBackMensageLine2] = useState("none");
  const [answerSelected, setAnswerSelected] =  useState("ADCA");
  const [correctAnswer, setCorrectAnswer] =  useState("");
  const [opacityText,setOpacityText] = useState(0);
  const handleAnswer = (answer) => {
    setOpacityText(0);
    setFeedBackMensageLine1('none');
    setFeedBackMensageLine2('none');
    onAnswerQuestion(answer);
    setDisabled(true);
    setTimeout(() => setDisabled(false), TIME_BUTTON_DOWN);
    setShowButton(false);
  };
  const nextAnswerQuestion = (answer) =>{
    setOpacityText(100);
    if(imageURL.search(answer) === -1){
      setFeedBackMensageLine1('Incorrect');
      if(answer === 'ADCA'){
        setCorrectAnswer('SCC');
        setFeedBackMensageLine2('Correct answer: SCC');
      }else{
        setCorrectAnswer('ADCA');
        setFeedBackMensageLine2('Correct answer: ADCA');
      }
      setCorrectShowFeedBack(false);
    }else{
      setCorrectShowFeedBack(true);
      setFeedBackMensageLine1('Correct');
      if(answer === 'ADCA'){
        setCorrectAnswer('ADCA');
        setFeedBackMensageLine2('Correct answer: ADCA');
      }else{
        setCorrectAnswer('SCC');
        setFeedBackMensageLine2('Correct answer: SCC');
      }
    }
    setAnswerSelected(answer);
    setShowButton(true);
  }
  return (
    <React.Fragment>
      <TextWrapper>
        <Text>{progress}</Text>
      </TextWrapper>
      <Wrapper>
        <BorderedImage
          blurred={disabled}
          style={{margin: 'auto'}}
          imageURL={imageURL}
          alt="Question"
        />
      </Wrapper>
      <TextWrapper>
        { correctShowFeedBack ?(
          <>
            <TextWrapper>
              <TextPositive style={{opacity: opacityText, height: 0}}  >{feedBackMensageLine1}</TextPositive>
            </TextWrapper>
            <Text style={{opacity: opacityText, height: 0}}>{ feedBackMensageLine2}</Text>
          </>
          )
        :<>
          <TextWrapper>
            <TextNegative style={{opacity: opacityText, height: 0}}>{feedBackMensageLine1}</TextNegative>
          </TextWrapper>
          <Text style={{opacity: opacityText, height: 0}}>{ feedBackMensageLine2}</Text>
        </>
        }
      </TextWrapper>
      <ButtonWrapper>
        { showButton ? (
          <Button 
            style={{
              margin: '8px',
              width: '291px',
            }}
            onClick={() => handleAnswer(answerSelected)}
            >
              
            <Text>{'Next'}</Text>
          </Button>

        ) : <>
            {possibleAnswers.map((answer) => (
              <Button
                style={{
                  margin: '8px',
                }}
                key={answer}
                disabled={disabled}
                onClick={() => nextAnswerQuestion(answer)}
              >
                <Text>{answer}</Text>
              </Button>
            ))}
            </>
        
        }
      </ButtonWrapper>
    </React.Fragment>
  );
};

Question.propTypes = {
  progress: PropTypes.string,
  imageURL: PropTypes.string,
  possibleAnswers: PropTypes.array,
  onAnswerQuestion: PropTypes.func,
};

export default Question;
