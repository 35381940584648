import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImageContainer = styled.div`
  border: 2px solid ${({theme}) => theme.tertiary};
  border-radius: 24px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  width: min(300px, 100%);

  img {
    transition: 0.1s all;
    width: 100%;
    height: intrinsic;
  }

  
`;

const BorderedImage = ({imageURL, style, blurred, ...props}) => {
  const classNames = blurred ? 'blurred' : '';
  return (
    <ImageContainer style={style} className={classNames}>
      <img src={imageURL} {...props} />
    </ImageContainer>
  );
};

BorderedImage.propTypes = {
  style: PropTypes.object,
  blurred: PropTypes.bool,
  imageURL: PropTypes.string,
};

export {BorderedImage};
