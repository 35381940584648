import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${({theme, margin}) =>
    margin ? theme.margin[margin] : theme.margin.md};
`;

const TextWrapper = styled(Wrapper)`
  text-align: ${({align}) => align || 'center'};
`;

const ButtonWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export {Wrapper, TextWrapper, ButtonWrapper};
