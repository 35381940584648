import {shuffle, slice, flatMap} from 'lodash';

const getImagesByAnswerType = () => {
  // Those images should be stored as an array
  const images = require('../../images.json');

  return images.reduce((acc, image) => {
    const {correctAnswer} = image;
    if (correctAnswer in acc) {
      acc[correctAnswer].push(image);
    } else {
      acc[correctAnswer] = [image];
    }
    return acc;
  }, {});
};

export const getRandomImages = (totalImages) => {
  const imagesByAnswerType = getImagesByAnswerType();
  const answerTypes = Object.keys(imagesByAnswerType);
  const totalImagesByAnswerType = Math.floor(totalImages / answerTypes.length);

  const imagesList = flatMap(answerTypes, (answerType) => {
    const shuffledImages = shuffle(imagesByAnswerType[answerType]);
    return slice(shuffledImages, 0, totalImagesByAnswerType);
  });
  return shuffle(imagesList);
};
