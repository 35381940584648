import React, {useState} from 'react';
import styled from 'styled-components';
import {Provider} from 'mobx-react';
import {ThemeProvider} from 'styled-components';
import {stores} from './stores';
import {Quiz, Welcome} from './pages';
import {Wrapper} from './components';
import theme from './theme';

const Container = styled.div`
  background: ${({theme}) => theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
`;

const CenteredScreen = styled.div`
  background: ${({theme}) => theme.container};
  border-radius: 24px;
  padding: 16px 32px;
  max-width: 500px;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 70%;
  height: intrinsic;
`;

const App = () => {
  const [started, setStarted] = useState(false);

  const startSurvey = () => {
    stores.survey.startSurvey();
    setTimeout(() => setStarted(true), 500);
  };

  return (
    <Provider {...stores}>
      <ThemeProvider theme={theme}>
        <Container>
          <CenteredScreen>
            <Wrapper style={{display: 'flex', justifyContent: 'center'}}>
              <Logo src="/static/university-logo.png"/>
            </Wrapper>
            {started ? (
              <Quiz />
            ) : (
              <Welcome onStart={() => startSurvey()} />
            )}
          </CenteredScreen>
        </Container>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
