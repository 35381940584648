export default {
  background: '#dbd8ec',
  container: '#e1abd1',
  primary: '#3e67f0',
  secondary: '#fa564b',
  tertiary: '#615a57',
  positive: '#3e67f0',
  negative: '#fa564b',
  margin: {
    md: '16px',
    sm: '8px',
    xs: '4px',
  },
  font: {
    md: '20px',
    sm: '16px',
    xs: '12px',
  },
};
