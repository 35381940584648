import React from 'react';
import PropTypes from 'prop-types';
import {TextWrapper, ButtonWrapper, Text, Button} from '../../../components';

const Summary = ({totalQuestions, correctAnswers, incorrectAnswers,ADCACorrects,SCCCorrects}) => (
  <React.Fragment>
    <TextWrapper>
      <Text weight="bold">
        Summary
      </Text>
    </TextWrapper>
    <TextWrapper>
      <Text>
        Correct answers: {correctAnswers}/{totalQuestions}
      </Text>
    </TextWrapper>
    <TextWrapper>
      <Text>
        ADCA : {ADCACorrects} / {totalQuestions/2}
      </Text>
    </TextWrapper>
    <TextWrapper>
      <Text>
        SCC : {SCCCorrects} / {totalQuestions/2}
      </Text>
    </TextWrapper>
    <ButtonWrapper>
      <Button onClick={() => window.open('/', '_self')}>
        <Text>Restart Quiz</Text>
      </Button>
    </ButtonWrapper>
  </React.Fragment>
);

Summary.propTypes = {
  totalQuestions: PropTypes.number,
  correctAnswers: PropTypes.number,
  incorrectAnswers: PropTypes.number,
};

export default Summary;
