import {API, graphqlOperation} from 'aws-amplify';

const fetch = (api, data) => API.graphql(graphqlOperation(api, data));

export const mutate = (mutationName, data) => {
  return new Promise((resolve, reject) =>
    import(`./mutations`)
      .then((mutations) => {
        const mutation = mutations[mutationName];
        if (!mutation) reject(Error('Invalid mutation'));
        resolve(fetch(mutation, {input: data}));
      }));
};
