import styled from 'styled-components';

const Text = styled.span`
  font-size: ${({theme, size}) => size ? theme.font[size] : theme.font.md};
  font-weight: ${({weight}) => weight || 'initial'};
`;

const LinkedText = styled.a`
  all: unset;
  cursor: pointer;
  font-size: ${({theme, size}) => size ? theme.font[size] : theme.font.md};
  font-weight: ${({weight}) => weight || 'initial'};
  text-decoration: underline;
`;

const TextPositive = styled.span`
  font-size: ${({theme, size}) => size ? theme.font[size] : theme.font.md};
  font-weight: ${({weight}) => weight || 'initial'};
  color: ${({theme, positive}) => theme.positive};
  font-weight: bold;
`;

const TextNegative = styled.span`
  font-size: ${({theme, size}) => size ? theme.font[size] : theme.font.md};
  font-weight: ${({weight}) => weight || 'initial'};
  color: ${({theme, negative}) => theme.negative};
  font-weight: bold;
`;

export default {TextPositive,TextNegative};
export {Text, LinkedText, TextPositive, TextNegative};
