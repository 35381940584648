/* eslint-disable require-jsdoc */
import Answer from '../Answer';
import {mutate} from '../../graphql';

class Survey {
  id = null;
  finished = false;
  totalQuestions = 0;
  totalAnswers = 0;
  correctAnswers = 0;
  incorrectAnswers = 0;
  userAgent = null;
  onFinish = null;
  answers = [];

  constructor(totalQuestions = 0, userAgent = null, onFinish = () => {}) {
    this.totalQuestions = totalQuestions;
    this.userAgent = userAgent;
    this.onFinish = onFinish;
    this.create();
  }

  _handleAnswer(correctAnswer, answer) {
    const formattedCorrectAnswer = correctAnswer.toUpperCase();
    const formattedAnswer = answer.toUpperCase();

    if (formattedCorrectAnswer === formattedAnswer) {
      this.correctAnswers += 1;
    } else {
      this.incorrectAnswers += 1;
    }

    this.totalAnswers += 1;
    if (this.totalAnswers === this.totalQuestions) {
      this.finished = true;
      this.onFinish();
    }
  }

  create() {
    mutate('createSurvey', this.toJson())
      .then((response) => response.data.createSurvey)
      .then((data) => this.id = data.id)
      .catch(console.error);
  }

  update() {
    mutate('updateSurvey', this.toJson())
      .catch(console.error);
  }

  addAnswer(imageName, correctAnswer, answer) {
    this.answers.push(
      new Answer(imageName, correctAnswer, answer),
    );
    this._handleAnswer(correctAnswer, answer);
    this.update();
  }

  toJson() {
    const json = {
      finished: this.finished,
      answers: this.answers.map((answer) => answer.toJson()),
      total_questions: this.totalQuestions,
      total_answers: this.totalAnswers,
      correct_answers: this.correctAnswers,
      incorrect_answers: this.incorrectAnswers,
      user_agent: this.userAgent,
    };
    if (!this.id) return json;
    return {id: this.id, ...json};
  };
};

export default Survey;
