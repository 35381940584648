/* eslint-disable require-jsdoc */
import {observable, action, computed, decorate} from 'mobx';
import {
  POSSIBLE_ANSWERS,
  TOTAL_QUESTIONS,
  STORAGE_PREFIX,
} from '../../constants';
import {getRandomImages} from '../../utils/image';
import Survey from '../../models/Survey';

class SurveyStore {
  images = [];
  survey = null;
  // Observables
  totalQuestions = TOTAL_QUESTIONS;
  questionNumber = 1;
  showSummary = false;
  summary = {};
  question = null;
  ADCACorrects = 0;
  SCCCorrects = 0;

  constructor() {
    this.images = getRandomImages(this.totalQuestions);
  }

  startSurvey() {
    const {userAgent} = navigator;
    this.images = getRandomImages(this.totalQuestions);
    this.survey = new Survey(
      this.totalQuestions,
      userAgent,
      this.onSurveyFinish.bind(this),
    );
    this.dealQuestion();
  }

  onSurveyFinish() {
    this.showSummary = true;
    this.summary = {
      totalAnswers: this.survey.totalAnswers,
      correctAnswers: this.survey.correctAnswers,
      incorrectAnswers: this.survey.incorrectAnswers,
      totalQuestions: this.totalQuestions,
      ADCACorrects: this.ADCACorrects,
      SCCCorrects: this.SCCCorrects,
    };
  }

  dealQuestion() {
    if (!this.images.length) return;
    const imageData = this.images.pop();
    this.question = {
      imageName: imageData.imageName,
      imageURL: `${STORAGE_PREFIX}/${imageData.imageName}`,
      possibleAnswers: POSSIBLE_ANSWERS,
      correctAnswer: imageData.correctAnswer,
    };
  }

  answerQuestion(answer) {
    this.questionNumber += 1;
    if(answer === this.question.correctAnswer){
      if(answer === "ADCA"){
        this.ADCACorrects += 1;
      }else{
        this.SCCCorrects += 1;
      }
    }
    this.survey.addAnswer(
      this.question.imageName,
      this.question.correctAnswer,
      answer,
    );
    this.dealQuestion();
  }

  get progress() {
    return `${this.questionNumber}/${this.totalQuestions}`;
  }
};

decorate(SurveyStore, {
  question: observable,
  totalQuestions: observable,
  questionNumber: observable,
  showSummary: observable,
  summary: observable,
  dealQuestion: action,
  answerQuestion: action,
  onSurveyFinish: action,
  progress: computed,
});

export default SurveyStore;
