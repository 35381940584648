import React from 'react';
import {inject, observer} from 'mobx-react';
import Summary from './Summary';
import Questions from './Questions';

const Quiz = inject('survey')(observer(({survey}) => {
  const {question, progress, showSummary, summary} = survey;

  return (
    <React.Fragment>
      {showSummary ? (
        <Summary {...summary} />
      ) : (
        <Questions
          onAnswerQuestion={(answer) => survey.answerQuestion(answer)}
          progress={progress}
          {...question}
        />
      )}
    </React.Fragment>
  );
}));

export default Quiz;
